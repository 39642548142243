	/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/10/2020 13:43
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("../../assets/fonts/Flaticon.eot");
    src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
        url("../../assets/fonts/Flaticon.woff2") format("woff2"),
        url("../../assets/fonts/Flaticon.woff") format("woff"),
        url("../../assets/fonts/Flaticon.ttf") format("truetype"),
        url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-24-hours-support:before { content: "\f100"; }
.flaticon-email:before { content: "\f101"; }
.flaticon-location:before { content: "\f102"; }
.flaticon-left-arrow:before { content: "\f103"; }
.flaticon-right-arrow:before { content: "\f104"; }
.flaticon-play-button:before { content: "\f105"; }
.flaticon-user:before { content: "\f106"; }
.flaticon-calendar:before { content: "\f107"; }
.flaticon-done:before { content: "\f108"; }
.flaticon-tick:before { content: "\f109"; }
.flaticon-play-button-1:before { content: "\f10a"; }
.flaticon-saving:before { content: "\f10b"; }
.flaticon-trustworthy:before { content: "\f10c"; }
.flaticon-right-quote:before { content: "\f10d"; }
.flaticon-car-insurance:before { content: "\f10e"; }
.flaticon-travel-insurance:before { content: "\f10f"; }
.flaticon-property-insurance:before { content: "\f110"; }
.flaticon-insurance:before { content: "\f111"; }
.flaticon-home-insurance:before { content: "\f112"; }
.flaticon-life-insurance-1:before { content: "\f113"; }
.flaticon-cross:before { content: "\f114"; }
.flaticon-language:before { content: "\f115"; }